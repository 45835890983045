import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "PCB Design Software and File Types",
  "description": "PCB Design Software, Gerber File Format",
  "author": "Nadya Lukman",
  "categories": ["electronics"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Designing a PCB, and communicating those design decisions depends on a series of files such as circuit schematic designs, Bill-of-Materials, PCB layouts and stackup design information.`}</p>
    <p>{`In order to allow for interoperability between the large number of Electronics Design software such as Allegro, Altium Designer, KiCAD and Eagle, a collection of files called Gerbers were developed. Gerber files allow for understanding PCB designs without being locked into a certain design software.`}</p>
    <h2 {...{
      "id": "file-types"
    }}>{`File Types`}</h2>
    <p>{`When generating your first PCB design, there are 2 key files you should prepare:`}</p>
    <h3 {...{
      "id": "cad-aperture-files"
    }}>{`CAD Aperture files`}</h3>
    <p>{`In the format of RS-274X (Gerber x1), Gerber x2, IPC-2581, ODB++`}</p>
    <h3 {...{
      "id": "bom-bill-of-materials"
    }}>{`BOM (Bill of Materials)`}</h3>
    <p>{`In the format of TXT, PDF, CSV, XLSV, etc.`}</p>
    <p>{`When generating your BOM files, there are few important components to be included:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Component name`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MPN (Manufacturer Part Number)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tag number, the component number in your design files`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Quantity`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Description`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Manufacturer`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`MPN of the component's alternative`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Other remarks`}</p>
      </li>
    </ul>
    <p><a parentName="p" {...{
        "href": "https://docs.google.com/spreadsheets/d/1mhC0SlswAIwoDJAcGHlPYYHEb-dSk3rYThIpOr6lBR8/edit?usp=sharing"
      }}>{`Download our BOM Template here`}</a></p>
    <p>{`In addition to the two above, two other file types could also be prepared to provide further information about your PCB:`}</p>
    <h3 {...{
      "id": "mechanical-files"
    }}>{`Mechanical Files`}</h3>
    <p>{`These contain the PCB's dimensions and structure`}</p>
    <p>{`In the format of DXF, STEP, or DWG`}</p>
    <p>{`These Mechanical files should contain information about:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The precise PCB outline.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The exact position and size of some internal grooves or cuts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Specific tolerance requirements.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`It can also include some customized panel information, such as drilling patterns, hole position information, layer stacking, positioning holes, special production requirements, etc.`}</p>
      </li>
    </ul>
    <h4 {...{
      "id": "schematic-diagram-files"
    }}>{`Schematic Diagram Files`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`File Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DXF/DWG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.dxf .dwg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DXF: CAD drawing exchange format (vector) (ASCII or binary); DWG: CAD drawing file, binary `}<strong parentName="td">{`AutoCAD`}</strong>{` supports both files.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Netlist Schematic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.NET`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`It is a netlist, you can choose a document or a project, and choose the type of netlist`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Orcad SDT Schematic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.sch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SDT3/4 binary schematic file, AD cannot be opened`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Orcad v7 Capture Design`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.dsn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Capture design files will be automatically converted, prone to errors and failures`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P-CAD V16 Schematic Design`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`.sch`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ASCII file`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "pcb-design-files"
    }}>{`PCB Design files`}</h4>
    <p>{`Which are compiled or generated by Allegro, Altium Designer, Mentor series, EAGLE, ZUKEN, KiCAD, etc., if you want to give your manufacturer the ability to easily alter designs, as Gerber files themselves aren't editable.`}</p>
    <p>{`In NexPCB, we accept all the file formats mentioned above. However, we would highly recommend using Allegro and Altium Designer for the choice of EDA.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`File Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Format`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ansoft Neutral`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.anf`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ansoft US electromagnetic technology simulation EDA software, acquired by ansis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CircuitMaker`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.CMPcbDoc`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Altium`}</strong>{`’s PCB layout tool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CircuitStudio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.CSPcbDoc`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Altium`}</strong>{`’s PCB drawing software, AD simplification, device selection`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DXF/DWG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.dxf .dwg`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`AutoCAD`}</strong>{` drawing vector format file, DataExchangeFile`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`HyperLynx`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.hyp`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mentor`}</strong>{`'s electronic design system-level simulation platform`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`IDF Board`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.brd .bdf .idb .emn .idf`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Intermediate Date Format circuit board file. Each IDF contains a combination of board files and library files, such as: EMN/EMP BRD/LIB BDF/LDF IDB/IDL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PARASOLID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.x_t`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3D geometric modeling intermediate data format`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`P-CAD ASCII`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.PCB`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Altium`}</strong>{`’s PCB layout modeling tool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PCBWorks`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.PWPcbDoc`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Altium Protel`}</strong>{` NXP’s PCB file format`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Protel Netlist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.net`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Altium Protel`}</strong>{` Netlist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Portel PCB 2.8 ASCII`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.pcb`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Altium Protel`}</strong>{` 2.8 PCB file`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SiSoft`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.csv`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SiSoft Quantum`}</strong>{`-SI simulation software`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Specctra Design`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.dsn`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Cadence`}</strong>{`'s PCB tool`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`STEP 3D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.step .stp`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ISO standard for the exchange of 3D models.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VRML`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.wrl`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Virtual Reality Modeling Language`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PDF3D`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><pre>{`.pdf`}</pre></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PDF document with 3D properties`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      